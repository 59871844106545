.back-button-explore{
  padding-left: 40px;
  padding-bottom: 20px;
  cursor: pointer;
}

.pack-info .title {
  font-size:21px;
  margin-bottom: .2rem;
}

p{
  font-size: 18px;
}

.pack-detail {
    display: flex;
    justify-content: space-around;
    padding-left: 40px;
    padding-right: 40px;
}

.pack-view {
    background-color: unset;
    border: unset;
    width: auto;
    margin-right: 50px;
}

@media screen and (max-width: 1000px) {
    .pack-detail { 
      flex-direction: column;
    }
    .pack-view {
      margin-right: 0px;
    }
}

.atb-3d-b.atb-danger.back {
  margin-left: 10px;
  background-color: #FFFFFF;
  color: #F24E56;
  box-shadow: 0px 4px rgb(208, 208, 208);
}

.atb-3d-b.atb-danger.back:active {
  transform: translateY(4px);
  box-shadow: 0px 0px;
}