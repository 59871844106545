.entry-info{
    display: block;
    justify-content: space-between;
    padding: 12px;
    white-space: nowrap;
}

.entry{
    margin-bottom: 10px;
}

.entry-title {
    overflow-x: hidden;
    margin-right: 2px;
}

.entry-date {
    color: gray;
    font-size: 14px;
}

.recent-entries .title {
    margin-bottom: 15px;
}

.recent-entries-title-bar{
    display:flex;
    justify-content: space-between;
    align-items: baseline;
}

.flame{
    width: 12px;
    margin-right: 8px;
}

.streak-icon{
    display: flex;
    align-items: baseline;
}

.streak-count-text{
    font-size: 22px;
}

.custom-card{
    width: 21rem;
    border-radius: 10px;
    cursor:pointer;
    border-color: white;
    box-shadow: 0px 2px #F0EFED;
}

@media screen and (max-width: 1000px) {
    .custom-card{
        width: auto;
    }
}

