.pack-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    /* max-width: 50%; */
}

.explore-title{
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 21px;
    font-weight: 500;
}

.featured-title{
    margin-top: 30px;
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 30px;
}