.firebaseui-container {
  margin: 0 0;

}

.app-container{
  padding: 0rem 8rem;
}

.loading-animation{
  width: 30%;
  height: 30%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 400px){
  .loading-animation{
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .app-container{
    padding: 0rem 1rem;
  }
}


body {
  text-align: left;
  font-family: 'museo-sans', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  background-color: #FAF8F5;
  color: #244557;
  padding: 50px;
}

.atb-3d-b.atb-large.atb-round.atb-danger.loggedout{
  margin-left: 1rem;
}

.landing-page__intro{
  padding: 60px 0 40px 0;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.landing-page__intro-header {
  margin: 0;
  padding-bottom: 40px;
  font-size: 64px;
  font-weight: 700;
}

.landing-page__intro-subheader{
  margin: 0;
  padding-bottom: 40px;
  font-size: 30px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .landing-page__intro-header {
    font-size: 32px;
  }

  .landing-page__intro-subheader{
    font-size: 20px;
  }

  .landing-page__intro-bullet {
    font-size: 16px;
  }
}

.landing-page__intro-bullet {
  font-size: 20px;
  padding-bottom: 8px;
  margin: 0;
}

.landing-page__img-container {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.landing-page__img-dude {
  position: relative;
  width: 160px;
  left: 700px;
  top: 490px;
  z-index: -1;
}

.landing-page__img-little-blobs {
  position: relative;
  width: 1280px;
  top: 180px;
  left: 400px;
  z-index: -3;
}

.landing-page__img-big-blobs {
  position: relative;
  width: 1760px;
  right: 100px;
  top: 310px;
  z-index: -2;
}

@media (min-width: 1440px) {
  .landing-page__img-little-blobs {
    width: 1280px;
    top: 320px;
    left: 310px;
  }
  
  .landing-page__img-big-blobs {
    width: 1760px;
    right: 100px;
    top: 420px;
  }
}

@media (min-width: 900px) and (max-width: 1440px) {
  .landing-page__img-dude {
    width: 160px;
    left: 700px;
    top: 490px;
  }
  
  .landing-page__img-little-blobs {
    width: 1280px;
    top: 180px;
    left: 400px;
  }
  
  .landing-page__img-big-blobs {
    width: 1760px;
    right: 100px;
    top: 300px;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .landing-page__img-dude {
    width: 120px;
    left: 500px;
    top: 490px;
  }
  
  .landing-page__img-little-blobs {
    width: 1020px;
    top: 180px;
    left: 320px;
  }
  
  .landing-page__img-big-blobs {
    width: 1600px;
    right: 290px;
    top: 345px;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .landing-page__img-dude {
    width: 120px;
    left: 180px;
    top: 490px;
  }
  
  .landing-page__img-little-blobs {
    width: 1020px;
    top: 180px;
    left: 260px;
  }
  
  .landing-page__img-big-blobs {
    width: 1600px;
    right: 60px;
    top: 345px;
  }
}

@media (max-width: 400px) {
  .landing-page__img-dude {
    width: 100px;
    left: 240px;
    top: 420px;
  }
  
  .landing-page__img-little-blobs {
    width: 900px;
    top: 180px;
    left: 260px;
  }
  
  .landing-page__img-big-blobs {
    width: 1400px;
    right: 0px;
    top: 295px;
  }
}

.congrats {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 200px;
}

.checkmark {
  display: inline;
  fill: #4F5C79;
  margin: 30px;
}

div .tab p {
  margin-bottom: 0rem;
}

.long-logo{
  padding-top: 8px;
  padding-bottom: 10px;
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.current-question {
  font-weight: 300;
  font-size: 24px;
}

@media screen and (max-width: 1000px) {
  .journal-container { 
    display: flex;
    flex-direction: column;
    width:auto;
  }
  .congrats {
    margin-right: 0px;
  }
  body {
    padding: 20px;
  }
}

.report-card{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tab {
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  margin-right: 30px;
  stroke-width: 0;
}

.tab.active img{
  stroke-width: 10;
}

.textfield {
  border: none;
  border-color: transparent;
  background-color: white;
  color: #4F5C79;
  padding-bottom: 40px;
  height: auto;
  width: 100%;
  overflow: auto;
  resize: none;
  font-size: 18px;
  font-weight: 300;
  outline: none;
  padding-left: 0px;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

::placeholder{
  color: #4F5C79;
  font-weight: 300;
}

/* BUTTONS */

.atb-3d-b:not(.atb-default) {color: #fff;}
.atb-3d-b.atb-danger {outline: none;background-color: #FF5A39;box-shadow: 0px 4px #D04A40;}
.atb-3d-b.atb-inactive {background-color: #ED9B9F;box-shadow: 0px 4px #BE9697;}
.atb-3d-b.atb-danger:hover {background-color: #ee444d;}
.atb-3d-b.atb-danger:active {transform: translateY(4px); box-shadow: 0px 0px;}

[class*="atb-"] {outline: 0;border: none;outline:none; font-family: 'franklin-gothic-urw', sans-serif; font-weight: 700;min-width: 95px;padding: 11px 15px;font-size: 16px;line-height: 1;white-space: nowrap;cursor: pointer;text-decoration: none !important;text-align: center;}
/* Button Size */
.atb-large {padding: 13px 20px;font-size: 14px;}
.atb-large[class*="atb-modern-"] {padding: 16px 25px;font-size: 17px;}
.atb-small {padding: 9px 12px;font-size: 14px;}
.atb-small:focus {outline: 0;}
/* Button Radius */
.atb-pill {-webkit-border-radius: 40px;border-radius: 40px;}
.atb-round {-webkit-border-radius: 5px;border-radius: 5px;}
/* Fullwidth Button */
.atb-fullwidth {width: 100%;-webkit-box-sizing: border-box;box-sizing: border-box;}

/* PROGRESS BAR */

.bar-container {
  display: block;
  padding-bottom: 20px;
}

.step {
  display: inline-block;
  justify-content: center;
  margin-right: 30px;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 2px solid #4F5C79;
  border-radius: 50%;
  transition: background .5s;
}
.step.selected {
  border: 2px solid #4F5C79;   
}
.step.completed {
  border: 2px solid #4F5C79;
  background: #4F5C79; 
}