.daily-entry-buttons{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: baseline;
}

.primer{
    padding-bottom: 20px;
}

.journal-container{
    display: flex;
    justify-content: space-between;
}

.daily-question{
    margin-right: 20px;
    max-width: 62%
}
  
.daily-question .title {
    font-weight: 500;
    font-size: 18px;
    padding-top: 10px;
}

.daily-question .primer {
    font-size: 27px;
    width: auto;
}

@media screen and (max-width: 1000px) {
    .daily-question {
        margin-right: 0px;
        max-width: 100%;
      }
}
