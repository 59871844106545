.back-button.detail{
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
}

.back-text{
    font-weight: 500;
    font-size: 18px;
    color: #F24E56;
    margin-left: 10px;
}

.subtitle{
    color: #6c757d;
    margin-bottom: .2rem;
}