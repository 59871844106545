.topic-card {
    flex: 0 0 auto;
    /* margin-right: 20px; */
    text-align: center;
    width: 33%;
}

.topic-card.image-cropper img{
    color: #FAF8F5;
    background: #FAF8F5;
    background-color: #FAF8F5;
}

.image-cropper {
    width: 10rem;
    height: 10rem;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid seagreen;
    cursor: pointer;
    margin: auto;
    margin-bottom: 10px;
}

.image-cropper:hover {
    border-width: 4px;
    animation: both;
}

@media screen and (max-width: 1000px) {
    .image-cropper { 
      margin-bottom: 10px;
      width: 8rem;
      height: 8rem;
    }
    .topic-card {
        width: 50%;
    }
  }
  
.rounded {
    display: block;
    margin: 0 auto;
    margin-top: -25%;
    height: auto;
    width: 100%;
}

.topic-info {
    text-align: center;
}