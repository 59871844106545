.mb-2.text-muted{
    margin-bottom: 0rem!important;
}

.post-entry-animation{
    width: 40%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 400px) {
    .post-entry-animation{
      width: 100%;
      height: 100%;
    }
  }