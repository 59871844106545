.topbar{
    display: flex;
    justify-content: space-between;
}

.logout {
    color: #F24E56;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.mood-info {
    padding: 20px;
}

.profile_entry {
    width: 28rem;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    border-color: white;
    box-shadow: 0px 2px #F0EFED;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.stats-container{
    display: flex;
}

.stat {
    font-size: 36px;
    margin-bottom: 0rem;
}

.stats-col {
    margin-right: 40px;
}

.profile-container{
    display: flex;
    justify-content: space-between;
  }

@media screen and (max-width: 1000px) {
    .profile-container { 
      display: flex;
      flex-direction: column;
      width:auto;
    }
    .profile_entry{
        width:auto;
    }
  }