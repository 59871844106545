.dudes-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* see notes below */
    column-gap: 1em;
    row-gap: 2em;
}

.dude-container{
    text-align: center;
}

.mood-dude{
    margin: auto;
}

.mood-dude.select{
    margin-bottom: 20px;
}

.mood-dude.select img:hover{
    cursor: default;
}

.mood-dude img:hover{
    cursor:pointer;
}

.mood-subinfo{
    text-align: center;
    padding-top: 15px;
    cursor: pointer;
}

.sub-text:hover{
    margin: 2px 20px;
}

.mood-title{
    text-align: center;
    padding-bottom: 40px;
}

.music-explain{
    margin-bottom: 0;
}

.sub-text{
    color: #F24E56;
    font-size: 15px;
    font-weight: 500;
    margin: 2px 10px;
    transition: 0.2s;
}

@media screen and (max-width: 400px) {
    .mood-dude{
      width: 50%;
      height: 50%;
    }
    .dudes-container{
        gap: 2em;
    }
  }