.title-bar-entry{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: baseline;
}

.pack-title-entry{
    font-weight: 500;
    margin-bottom: 0;
}

.entry-flow-container{
    background-color: white;
    padding: 1rem 4rem;
    width: 100%;
}

.back-button{
    cursor: pointer;
    display: block;
}

@media screen and (max-width: 1000px) {
    .entry-flow-container {
        padding: 1rem 1rem;
    }
    .title-bar-entry{
        flex-wrap: wrap;
        line-height: 35px;
    }
    .step{
        margin-right: 15px;
    }
}