.card-container{
    width: 18rem;
    margin-right: 15px;
}

.pack-card {
    border: none;
    margin-bottom: 5px;
    margin-right: 20px;
    cursor: pointer;
}

svg[class^="thumbtack"]{
    transition: .2s;
}

svg[class^="thumbtack"]:hover{
    padding-top: 2px;
}

.subCardInfo {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
}

.subCardInfo * {
    flex: 1;
}

@media screen and (max-width: 1000px) {
    .pack-card{
        margin-right: 5px;
    }
    .subCardInfo {
        margin-right: 2px;
    }
}